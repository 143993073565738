import React from "react";
import { CircularProgress } from "@material-ui/core";

const Loading = ({ open }) => {
  return (
    <div style={{ ...styles.backdrop, display: open ? "flex" : "none" }}>
      <CircularProgress color="inherit" />
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "999999999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0,0,0,0.4)",
    color: "white",
  },
};
export default Loading;
