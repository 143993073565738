import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import "./main.css";

const LastYearNumbers = () => {
  const [totalAmount, setTotalAmount] = useState({
    totalThisYear: 0,
    totalLastYear: 0,
  });
  // const [totalDonors, setTotalDonors] = useState({
  //   totalThisYear: 0,
  //   totalLastYear: 0,
  // });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_NEW_BASEURL_APIS}/dashboard/numbers`
        );
        const numbers = response.data;

        var nf = Intl.NumberFormat();
        // var x = 42000000;
        // console.log(nf.format(x));

        setTotalAmount({
          totalThisYear: nf.format(numbers.actualYear.total),
          totalLastYear: nf.format(numbers.lastYear.total),
        });
        // setTotalDonors({
        //   totalThisYear: numbers.actualYear.nbreDonor,
        //   totalLastYear: numbers.lastYear.nbreDonor,
        // });
      } catch (error) {
        console.log(error);
      }
    };
    getData();
    return () => {
      setTotalAmount({
        totalThisYear: 0,
        totalLastYear: 0,
      });
      // setTotalDonors({
      //   totalThisYear: 0,
      //   totalLastYear: 0,
      // });
    };
  }, []);
  return (
    <div className="container__inner lastyearnumbers_container">
      <div align="center" className=""></div>
      <div align="center" className="lastyearnumbers_item">
        <h3>Total Dons (€)</h3>
        <p>
          {new Date().getFullYear() - 1}
          {" : "}
          <span>{totalAmount.totalLastYear}</span>
        </p>
        <p>
          {new Date().getFullYear()}
          {" : "}
          <span>{totalAmount.totalThisYear}</span>
        </p>
      </div>
      {/* <div align="center" className="lastyearnumbers_item">
        <h3>~ Nbre Donateurs</h3>
        <p>
          {new Date().getFullYear() - 1}
          {" : "}
          <span>{totalDonors.totalLastYear}</span>
        </p>
        <p>
          {new Date().getFullYear()}
          {" : "}
          <span>{totalDonors.totalThisYear}</span>
        </p>
      </div> */}
      <div align="center" className="">
        {/* <h3>~ Nbre Engagements</h3>
        <p>
          {new Date().getFullYear() - 1}
          {" : "}
          <span>0</span>
        </p>
        <p>
          {new Date().getFullYear()}
          {" : "}
          <span>0</span>
        </p> */}
      </div>
    </div>
  );
};

export default LastYearNumbers;
