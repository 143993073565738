import React from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { removeAlert } from "../providers/actions/alert";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const CustomAlert = ({ alert, removeAlert }) => {
  return alert && alert.msg ? (
    <div style={customStyles.alertCont}>
      <Alert
        severity={alert.alertType}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              removeAlert();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {alert.msg}
      </Alert>
    </div>
  ) : (
    ""
  );
};

CustomAlert.propTypes = {
  alert: PropTypes.object.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

const customStyles = {
  alertCont: {
    width: "600px",
    maxWidth: "90%",
    margin: "10px auto auto",
    zIndex: "9999",
  },
};

export default connect(mapStateToProps, { removeAlert })(CustomAlert);
