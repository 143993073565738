import React, { useState, useCallback } from "react";
import clsx from "clsx";
import "../App.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

// components
import Header from "./Header";

import Loader from "./Loader";
import CustomAlert from "./CustomAlert";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    marginTop: "40px",
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const DefaultLayout = (props) => {
  const { children } = props;
  const classes = useStyles();

  const [darkState, setDarkState] = useState(false);

  const palletType = darkState ? "dark" : "light";

  const theme = createTheme({
    palette: {
      type: palletType,
      primary: {
        light: "#ff9f50",
        main: "#f36e20",
        dark: "#b93e00",
        contrastText: "#fff",
      },
      secondary: {
        light: "#6d6d6d",
        main: "#424242",
        dark: "#1b1b1b",
        contrastText: "#ffffff",
      },
    },
  });
  const [open, setOpen] = useState(true);

  const headerDrawerCallback = useCallback((menuOpen) => {
    setOpen(menuOpen);
  }, []);
  const headerDarkCallback = useCallback((darkAppBar) => {
    setDarkState(darkAppBar);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Loader />
        <CssBaseline />
        <Header
          darkCallback={headerDarkCallback}
          drawerCallback={headerDrawerCallback}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <CustomAlert />
          {children}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default DefaultLayout;
