import React from "react";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Loader = ({ loader }) => {
  return (
    <div
      style={{
        ...styles.backdrop,
        display: loader.payload
          ? loader.payload.isLoading
            ? "flex"
            : "none"
          : "none",
      }}
    >
      <CircularProgress color="inherit" />
    </div>
  );
};
const styles = {
  backdrop: {
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "999999999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0,0,0,0.4)",
    color: "white",
  },
};

Loader.propTypes = {
  loader: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loader: state.loader,
});

export default connect(mapStateToProps)(Loader);
