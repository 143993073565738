import React, { useState, useEffect } from "react";
import Table from "../../Components/Table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import API from "../../utils/API";
import NewCampaign from "./NewCampaign";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const headCells = [
  {
    field: "name",
    title: "Campagne",
  },
  {
    field: "campaignParent",
    title: "Campagne parent",
  },
  {
    field: "donations",
    title: "Nombre de dons",
    type: "numeric",
  },
];

const CampaignsOthers = ({ history }) => {
  const classes = useStyles();
  const [donations, setDonations] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  useEffect(() => {
    const getDonations = async () => {
      try {
        setOpen(true);

        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns/autres`
        );

        const data = response.data.campaigns;

        var dataToSet = [];

        Object.keys(data).map((value, index) => {
          data[value].campaignParent = "Autres";

          dataToSet.push(data[value]);

          return dataToSet;
        });

        setDonations(dataToSet);

        setOpen(false);
      } catch (error) {
        console.log(error);
        setOpen(false);
        alert("ERROR OCCURED");
      }
    };
    getDonations();
  }, [reload]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [campName, setCampName] = useState(false);

  const handleDialogOpencallback = (open, campaignName) => {
    setCampName(campaignName);
    setDialogOpen(open);
  };

  const newCampaignCallback = ({ dialogOpen, rereload }) => {
    setDialogOpen(dialogOpen);
    setReload(!reload);
  };

  return (
    <div style={{ width: "90%", margin: "100px auto" }}>
      <h1>Dons de la campagne Autres </h1>
      <Table
        title={""}
        headCells={headCells}
        data={donations}
        singleLink={"/autres/"}
        singleIdSelector={"name"}
        singleOption={""}
        singleOption2={""}
        pagination={true}
        actionHidden={false}
        actionIcon={"update + details"}
        history={history}
        handleDialogOpencallback={handleDialogOpencallback}
      />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NewCampaign
        parentCallback={newCampaignCallback}
        visible={dialogOpen}
        campaignName={campName}
        provider={"campaignOthers"}
      />
    </div>
  );
};

export default CampaignsOthers;
