import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import API from "../../../utils/API";

import { connect } from "react-redux";
import { showLoader } from "../../../providers/actions/loader";
import { setAlert } from "../../../providers/actions/alert";
import PropTypes from "prop-types";

const RefundRequestPopup = ({
  refundDialogOpen,
  refunDialogCallback,
  donationId,
  showLoader,
  setAlert,
}) => {
  const handleCloseDialog = () => {
    refunDialogCallback(false);
  };

  const handleRefundRequestSubmit = async (e) => {
    e.preventDefault();

    const refundReason = e.target.reason.value;

    if (!refundReason) {
      alert("le motif du remboursement est obligatoire");

      return;
    }

    // send request here
    try {
      showLoader(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/refund-requests/${donationId}/create`,
        {
          donationId,
          reason: refundReason,
        }
      );
      if (res.code === 200) {
        showLoader(false);
        setAlert("Demande crée avec succès", "success");
        refunDialogCallback(false);
      } else if (res.code === 400) {
        showLoader(false);
        setAlert(res.msg, "info");
      }
    } catch (error) {
      showLoader(false);
      setAlert("Une erreur est survenue", "error");
      console.log(error);
    }
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="simple-dialog-title"
      open={refundDialogOpen}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="simple-dialog-title">
        Demande de remboursement
      </DialogTitle>
      <div style={{ padding: "20px" }}>
        <form onSubmit={handleRefundRequestSubmit}>
          <div>
            <TextField
              id="standard-multiline-flexible"
              name="reason"
              label="Motif"
              multiline
              rows={4}
              fullWidth={true}
            />
          </div>
          <div align="center">
            <br />
            <Button type="submit" color="primary" variant="contained">
              Envoyer
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

RefundRequestPopup.propTypes = {
  showLoader: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { showLoader, setAlert })(RefundRequestPopup);
