import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { loadUser, loggOut } from "../providers/actions";
import API from "../utils/API";

const RouteWithLayout = (props) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoggedIn === true) {
      (async () => {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_NEW_BASEURL_APIS}/api/users/reporting/logged-in`
        );
        if (response.code === 200) {
          dispatch(
            loadUser({
              ...response.user,
              email: response.user.email,
              name: response.user.name,
              userId: response.user.id,
            })
          );
        } else {
          dispatch(loggOut());
        }
      })();
    }
  }, [dispatch, isLoggedIn]);

  const { layout: Layout, component: Component, ...rest } = props;
  return isLoggedIn ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect to="/login" />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
