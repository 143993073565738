import React, { useState } from "react";

import API from "../../../utils/API";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "8px 0",
    minWidth: "100%",
  },
}));

const NewParentCampaign = ({ callback, visibleparent }) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleDialogClose = (reload = false) => {
    callback({
      dialogOpen: false,
      rereload: reload,
    });
  };

  //handle form
  const [newCampaignName, setNewCampaignName] = useState("");

  const handleCampaignNameChange = (event) => {
    setNewCampaignName(event.target.value);
  };

  const handleFormSubmit = async () => {
    setSeverity("success");
    setAlertMsg("Campagne ajouté avec succès");
    setCollapsed(false);
    if (newCampaignName !== "") {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/campaigns-parent`,
        {
          campaignName: newCampaignName,
        }
      );

      if (response.code === 200) {
        setCollapsed(false);
        handleDialogClose(true);
        setNewCampaignName("");
      } else {
        //throw error
        setSeverity("error");
        setAlertMsg(
          "Une erreur est survenue durant l'insersion dans la base de donnée, contactez l'administrateur du site"
        );
        setCollapsed(true);
      }
    } else {
      setSeverity("error");
      setAlertMsg("Veillez entrer le nom de campagne parent");
      setCollapsed(true);
    }
  };

  return (
    <div>
      <Dialog
        open={visibleparent}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nouvelle campagne</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous pouvez ajouter une nouvelle campagne parent.
          </DialogContentText>
          <Collapse in={collapsed}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setCollapsed(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {alertMsg}
            </Alert>
          </Collapse>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="campaign-name"
              label="Nom de la campagne"
              type="text"
              fullWidth
              value={newCampaignName}
              onChange={handleCampaignNameChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewParentCampaign;
