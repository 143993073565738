import React, { useState } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../../../providers/actions/loader";
import PropTypes from "prop-types";

import { Button, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import API from "../../../../utils/API";

const SearchDonorPopup = ({ searchDonorCallback, showLoader }) => {
  const [donorsResult, setDonorsResult] = useState([]);

  const [notExistFirst, setNotExistFirst] = useState(false);

  const [searchKey, setSearchKey] = useState("");

  const handleSreachDonorsChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleSreachDonors = async () => {
    if (searchKey) {
      try {
        showLoader(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donors/search?key=${searchKey}`
        );

        if (res.code === 200) {
          showLoader(false);
          setNotExistFirst(false);

          setDonorsResult(res.data);
        } else if (res.code === 404) {
          showLoader(false);
          setNotExistFirst(true);

          setDonorsResult([]);
        } else {
          showLoader(false);
          console.log("error", res);
        }
      } catch (error) {
        showLoader(false);
        alert("ERROR OCCURED");
        console.log(error);
      }
    }
  };

  return (
    <Paper className="search__donor__popup__container">
      <h3>Selectionner le donnateur s'il existe</h3>
      <input
        type="text"
        className="filter_input"
        name="donateurs"
        placeholder="Recherchez un donateur (email / nom / prénom)"
        onChange={handleSreachDonorsChange}
        style={{ marginBottom: "20px" }}
      />
      <Button
        aria-label="rechercher"
        variant="contained"
        color="primary"
        fullWidth={true}
        onClick={handleSreachDonors}
        startIcon={<SearchIcon fontSize="inherit" />}
      >
        Rechercher
      </Button>
      <div className="search__donor__popup__donors_list">
        {donorsResult && donorsResult.length > 0 ? (
          <>
            <div className="search__donor__popup__donors_list__inner">
              <div>Prénom - Nom </div>
              <div>Email</div>
              <div align="right">Action</div>
            </div>
            {donorsResult.map((donor, index) => (
              <div
                className="search__donor__popup__donors_list__inner"
                key={index}
              >
                <div>
                  {donor.firstName}-{donor.lastName}
                </div>
                <div>{donor.user ? donor.user.email : ""}</div>
                <div align="right">
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => searchDonorCallback(donor)}
                  >
                    Celui ci
                  </Button>
                </div>
              </div>
            ))}
            <div align="center">
              <Button
                size="small"
                color="secondary"
                variant="contained"
                style={{ width: "auto" }}
                onClick={() => searchDonorCallback("")}
              >
                Accun donateur ne correspond
              </Button>
            </div>
          </>
        ) : (
          <div align="center">
            {notExistFirst ? (
              <Button
                size="small"
                color="secondary"
                variant="contained"
                style={{ width: "200px" }}
                onClick={() => searchDonorCallback("")}
              >
                Donateur n'existe pas
              </Button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </Paper>
  );
};
SearchDonorPopup.propTypes = {
  showLoader: PropTypes.func.isRequired,
};
export default connect(null, { showLoader })(SearchDonorPopup);
