import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";

// redux
import { useSelector } from "react-redux";

import { Button, Paper } from "@material-ui/core";

import API from "../../../utils/API";

import CurrencyFormat from "../../../Components/CurrencyFormat";
import FormatDate from "../../../Components/FormatDate";

const RefundDetails = () => {
  const { id } = useParams();

  const loggedInUserInfo = useSelector((state) => state.isLogged);

  const [actionsDisabled, setActionsDisabled] = useState(true);

  useEffect(() => {
    if (loggedInUserInfo) {
      setActionsDisabled(loggedInUserInfo.restrictEdit);
    }
  }, [loggedInUserInfo]);

  const [refundRequest, setRefundRequest] = useState({});
  const [reloadPage, setReloadPage] = useState(false);

  useEffect(() => {
    const getRefundRequest = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/refund-requests/${id}`
        );

        if (res.code === 200) {
          if (res.data.length > 0) setRefundRequest(res.data[0]);
        } else {
          console.log(res.code, "error occured");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getRefundRequest();
    return () => {
      setRefundRequest({});
    };
  }, [id, reloadPage]);

  const handleApprouveRequest = async () => {
    console.log("approuve");
    try {
      const res = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/refund-requests/${id}/approve`
      );

      if (res.code === 200) {
        setReloadPage(!reloadPage);
      } else {
        console.log(res.code, "error occured");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelRequest = async () => {
    console.log("cancel");
    try {
      const res = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/refund-requests/${id}/cancel`
      );

      if (res.code === 200) {
        setReloadPage(!reloadPage);
      } else {
        console.log(res.code, "error occured");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return refundRequest ? (
    <div style={{ margin: "100px auto" }}>
      <div className="refund__request__details">
        <div align="right" style={{ marginBottom: "20px" }}>
          <span
            className={`status__badge ${
              refundRequest.status ? refundRequest.status.replace(" ", "_") : ""
            }`}
          >
            {refundRequest.status}
          </span>
        </div>

        <div className="refund__request__details__inner">
          <Paper className="page__details__card_container">
            <p>
              <b>Don ID : </b>{" "}
              <Link
                to={
                  refundRequest.donation
                    ? `/donation/${refundRequest.donation.id}`
                    : "#"
                }
              >
                {refundRequest.donation ? refundRequest.donation.id : ""}
              </Link>
            </p>
            <p>
              <b>Montant :</b> <CurrencyFormat amount={refundRequest.amount} />
            </p>
            <p>
              <b>Demandé par :</b>{" "}
              {refundRequest.reaquestedBy
                ? refundRequest.reaquestedBy.name
                : ""}
            </p>
            <p>
              <b>
                Demandé le :{" "}
                <FormatDate date={refundRequest.createdAt} withTime={true} />
              </b>
            </p>
            {refundRequest.statusGeteway &&
            refundRequest.statusGeteway !== "NULL" ? (
              <p>
                <b>Passerelle status : {refundRequest.statusGeteway}</b>
              </p>
            ) : (
              ""
            )}
          </Paper>

          <Paper className="page__details__card_container">
            <p>
              <b>Motif : </b> {refundRequest.reason}
            </p>
          </Paper>
        </div>
      </div>
      <div className="refund__request__details__inner">
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#2e902e", color: "#fff" }}
          onClick={handleApprouveRequest}
          disabled={actionsDisabled}
        >
          Approuver
        </Button>

        <Button
          variant="contained"
          style={{ backgroundColor: "#c82d2d", color: "#fff" }}
          onClick={handleCancelRequest}
          disabled={actionsDisabled}
        >
          Désapprouver
        </Button>
      </div>
      <h3>Details de don et du donateur</h3>
      <Paper className="page__details__card_container">
        <div className="refund__request__details__inner">
          <div>
            <p>
              <b>Montant : </b> <CurrencyFormat amount={refundRequest.amount} />
            </p>
            <p>
              <b>Passerelle : </b>{" "}
              {refundRequest.donation ? refundRequest.donation.geteway : ""}
            </p>
            <p>
              <b>Regularité : </b>{" "}
              {refundRequest.donation ? refundRequest.donation.regularity : ""}
            </p>
            <p>
              <b>Date de don : </b>{" "}
              <FormatDate
                date={
                  refundRequest.donation
                    ? refundRequest.donation.paymentDate
                    : ""
                }
              />
            </p>
            <p>
              <b>Campagne : </b>{" "}
              {refundRequest.donation
                ? refundRequest.donation.campaign.name
                : ""}
            </p>
            <p>
              <b>Campagne Parent : </b>{" "}
              {refundRequest.donation
                ? refundRequest.donation.campaignParent.name
                : ""}
            </p>
          </div>
          <div>
            <p>
              <b>Prénom : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.firstName : ""}
            </p>
            <p>
              <b>Nom : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.lastName : ""}
            </p>
            <p>
              <b>Télephone : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.phoneNumber : ""}
            </p>
            <p>
              <b>Raison sociale : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.company : ""}
            </p>
            <p>
              <b>Adresse : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.address : ""}
            </p>
            <p>
              <b>Ville : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.city : ""}
            </p>
            <p>
              <b>Pays : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.country : ""}
            </p>
            <p>
              <b>Code postal : </b>{" "}
              {refundRequest.donor ? refundRequest.donor.zipCode : ""}
            </p>
          </div>
        </div>
      </Paper>
    </div>
  ) : (
    "No details"
  );
};

export default RefundDetails;
