import isLoggedReducer from "./isLoggedIn";
import loader from "./loader";
import alert from "./alert";

import { combineReducers } from "redux";

const allReducers = combineReducers({
  isLogged: isLoggedReducer,
  loader,
  alert,
});

export default allReducers;
