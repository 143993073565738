import React from "react";
import DonorByYear from "./DonorByYear";

import LastMonthNumbers from "./LastMonthNumbers";
import LastYearNumbers from "./LastYearNumbers";

function Dashboard({ history }) {
  return (
    <div className="container__page">
      <LastYearNumbers />
      <LastMonthNumbers />
      <DonorByYear />
      {/* <div
        style={{
          width: "90%",
          margin: "100px auto",
        }}
      >
        <iframe
          frameBorder="0"
          title="analytics"
          height="1500px"
          width="100%"
          src="https://analytics.zoho.eu/open-view/31745000001511601"
        ></iframe>
      </div> */}
    </div>
  );
}
export default Dashboard;
