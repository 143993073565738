import React, { useState, useEffect } from "react";
import Table from "../../Components/Table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import API from "../../utils/API";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const formatDate = (date) => {
  let dateToFormat = new Date(date);
  let dd = dateToFormat.getDate();
  let mm = dateToFormat.getMonth() + 1;

  let yyyy = dateToFormat.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "-" + mm + "-" + yyyy;
};
const headCells = [
  {
    field: "paymentDate",
    title: "Date",
    render: (rowData) => <span>{formatDate(rowData.paymentDate)}</span>,
  },
  {
    field: "campaign.name",
    title: "Campagne",
  },
  {
    field: "amount",
    title: "Amount",
    type: "currency",
    currencySetting: { locale: "fr-EU", currencyCode: "EUR" },
  },
  {
    field: "donor.email",
    title: "Donor email",
  },
];
const OthersDetails = ({ match, history }) => {
  const classes = useStyles();
  const [donations, setDonations] = useState([]);
  const [open, setOpen] = useState(false);

  let campaignName = match.params.campname;
  // replace ___ with / in backEnd
  // if (match.params.campname) {
  //   campaignName = match.params.campname.replace(/___/g, "/");
  // }

  if (!campaignName) {
    campaignName = " ";
  }

  useEffect(() => {
    const getDonations = async (campaignName) => {
      try {
        setOpen(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns/autres/${campaignName}/details`
        );

        // console.log(response);

        setDonations(response.data);
        setOpen(false);
      } catch (error) {
        console.log(error);
        alert("ERROR OCCURED");
        setOpen(false);
      }
    };
    getDonations(campaignName);
  }, [campaignName]);

  return (
    <div style={{ width: "90%", margin: "100px auto" }}>
      <Table
        title={"Autres"}
        headCells={headCells}
        data={donations}
        singleLink={"/donation/"}
        singleIdSelector={"id"}
        singleOption={""}
        pagination={true}
        actionHidden={false}
        history={history}
      />
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default OthersDetails;
