import React, { useState, useEffect } from "react";
import Table from "../../Components/Table";

import { connect } from "react-redux";
import { showLoader } from "../../providers/actions/loader";
import PropTypes from "prop-types";

import API from "../../utils/API";

const headCells = [
  {
    field: "paymentDate",
    title: "Date",
    type: "date",
  },
  {
    field: "campaignParent.name",
    title: "Campagne_parent",
  },
  {
    field: "geteway",
    title: "Passerelle",
  },
  {
    field: "regularity",
    title: "Regularité",
  },
  {
    field: "amount",
    title: "montant",
    type: "currency",
    currencySetting: { locale: "fr-EU", currencyCode: "EUR" },
  },
  {
    field: "user.email",
    title: "Donor_email",
  },
];
const DonationsByCampaign = ({ match, history, showLoader }) => {
  const [donations, setDonations] = useState([]);

  const campaignId = match.params.id;
  const campaignDate = match.params.date;
  const campaignDateTo = match.params.dateto;

  useEffect(() => {
    const getDonations = async (campaignId) => {
      try {
        showLoader(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donations/${campaignId}/${campaignDate}/${campaignDateTo}`
        );
        if (response.code === 200) {
          setDonations(response.data);
          showLoader(false);
        } else {
          console.log("error", "une erreur est survenue");
          showLoader(false);
        }
      } catch (error) {
        console.log("error", error);
        showLoader(false);
      }
    };
    getDonations(campaignId);
  }, [campaignId, campaignDate, campaignDateTo, showLoader]);

  return (
    <div style={{ width: "90%", margin: "100px auto" }}>
      <Table
        title={
          campaignDateTo
            ? " De " + campaignDate + " À " + campaignDateTo
            : campaignDate
        }
        headCells={headCells}
        data={donations}
        singleLink={"/donation/"}
        singleIdSelector={"id"}
        singleOption={""}
        pagination={true}
        actionHidden={false}
        history={history}
      />
    </div>
  );
};

DonationsByCampaign.propTypes = {
  showLoader: PropTypes.func.isRequired,
};

export default connect(null, { showLoader })(DonationsByCampaign);
