import React, { useState, useEffect } from "react";

import { useParams } from "react-router";

const FiscalReceiptDetails = () => {
  let { id } = useParams();

  const [rfUrl, setRfUrl] = useState("");

  useEffect(() => {
    setRfUrl(`${process.env.REACT_APP_NEW_BASEURL_APIS}/recus-fiscaux/${id}`);

    return () => {
      setRfUrl("");
    };
  }, [id]);

  return (
    <div style={{ margin: "100px auto" }}>
      {rfUrl && id ? (
        <iframe
          title="RF"
          id="pdfPreview"
          width="100%"
          height="700px"
          type="application/pdf"
          style={{ border: "none" }}
          src={rfUrl}
        >
          <p>Le PDF ne peut pas être affiché.</p>
        </iframe>
      ) : (
        <h4 align="center">
          Url du RF n'est pas valide{" "}
          <span role="img" aria-label="sry">
            😒
          </span>
        </h4>
      )}
    </div>
  );
};

export default FiscalReceiptDetails;
