import { SHOW_LOADING } from "../actions/types";
const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LOADING:
      return { ...state, payload };
    default:
      return state;
  }
};
