import React, { useState } from "react";

import {
  Paper,
  CircularProgress,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

import { Link } from "react-router-dom";

import API from "../utils/API";
import FormatDate from "./FormatDate";

const Search = () => {
  const [resultsCount, setResultsCount] = useState(0);
  const [results, setResults] = useState([]);
  const [loaderSearchDisplay, setLoaderSearchDisplay] = useState("none");

  const [searchKey, setSearchKey] = useState("");
  const handleSearchKeyChange = (e) => {
    setSearchKey(e.target.value);
  };

  const handleSearchDialoClose = () => {
    //setSearchDialogOpen(false);
    document.querySelector("#search-panel").style.display = "none";
    document.querySelector("#search-panel-results").style.display = "none";
  };

  const handleSearchOpen = async (event) => {
    document.querySelector("#search-panel").style.display = "block";
    document.querySelector("#search-panel-results").style.display = "block";
  };

  const handleSearch = async () => {
    let keywords = searchKey.trim();

    if (keywords.length >= 2) {
      document.querySelector("#search-panel").style.display = "block";
      document.querySelector("#search-panel-results").style.display = "block";

      setLoaderSearchDisplay("inline-block");
      try {
        const res = await API(
          "POST",
          `${process.env.REACT_APP_API_BASE_URL}/search`,
          {
            key: keywords,
          }
        );

        // setKeywordSearch(keywords);
        setResults(res);
        setResultsCount(res.length);
        setLoaderSearchDisplay("none");
      } catch (error) {
        console.log(error);
        setLoaderSearchDisplay("none");
      }
    }
  };

  return (
    <div>
      <IconButton onClick={handleSearchOpen} style={{ color: "white" }}>
        <SearchIcon />
      </IconButton>

      <div style={customStyles.searchOverlay} id="search-panel">
        <Paper
          style={customStyles.searchResultsContainer}
          id="search-panel-results"
        >
          <CloseIcon
            style={customStyles.closeIcon}
            onClick={handleSearchDialoClose}
          />

          <div align="center">
            <FormControl variant="outlined" fullWidth={true} size="small">
              <InputLabel htmlFor="outlined-input-search">
                Rechercher
              </InputLabel>
              <OutlinedInput
                id="outlined-input-search"
                onChange={handleSearchKeyChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      onClick={handleSearch}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby="outlined-input-search-helper-text"
                labelWidth={100}
              />
              <FormHelperText
                id="outlined-input-search-helper-text"
                style={{ display: "flex" }}
              >
                <InfoIcon fontSize="small" /> Vous pouvez utiliser dans votre
                recherche: Id_dons | Email | Passerelle_id | Nom Prénom
              </FormHelperText>
            </FormControl>
            <span style={customStyles.customBadge}>{resultsCount}</span>{" "}
            Résultas trouvés{" "}
            <CircularProgress
              style={{
                display: loaderSearchDisplay,
                width: "15px",
                height: "15px",
              }}
              color="inherit"
            />
          </div>

          <table style={customStyles.table}>
            <tbody>
              {results.map((donation) => (
                <tr key={donation.id} style={customStyles.trTableearch}>
                  <td>{donation.email}</td>
                  <td>{donation.fullName}</td>
                  <td>{donation.geteway}</td>
                  <td>
                    <FormatDate date={donation.paymentDate} />
                  </td>
                  <td>{donation.status}</td>
                  <td>
                    <Link
                      to={`/donation/${donation.id}`}
                      style={customStyles.linkStyle}
                    >
                      Détails
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
      </div>
    </div>
  );
};

export default Search;

const customStyles = {
  customBadge: {
    background: "#f36e20",
    color: "white",
    borderRadius: 25,
    padding: "0 8px 1px",
    fontSize: "15px",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  closeIcon: {
    position: "absolute",
    right: 10,
    top: 10,
    color: "#f36e20",
    width: "1em",
    height: "auto",
    cursor: "pointer",
  },
  searchResultsContainer: {
    width: "800px",
    maxWidth: "100%",
    maxHeight: "90vh",
    height: "auto",
    position: "fixed",
    zIndex: 999,
    top: 66,
    right: 0,
    left: 0,
    margin: "auto",
    padding: "50px 10px",
    overflowY: "auto",
    display: "none",
  },
  searchOverlay: {
    position: "fixed",
    zIndex: 9,
    top: 64,
    left: 0,
    right: 0,
    bottom: 0,
    background: "#00000066",
    display: "none",
  },
  linkStyle: {
    textDecoration: "none",
  },
  trTableearch: {
    borderBottom: "solid 1px #cccccc",
    fontSize: "14px",
  },
};
