import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { showLoader } from "../../../providers/actions/loader";
import { setAlert } from "../../../providers/actions/alert";
import PropTypes from "prop-types";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
} from "@material-ui/core";
import CurrencyFormat from "../../../Components/CurrencyFormat";

import API from "../../../utils/API";

var yearsDispo = [];
for (let index = 2020; index <= new Date().getFullYear(); index++) {
  yearsDispo.push(index);
}

const RFStats = ({ showLoader, setAlert }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [rfNumberTotal, setRfNumberTotal] = useState(0);
  const [rfNumberByYear, setRfNumberByYear] = useState(0);

  const [donationsNumberByYear, setDonationsNumberByYear] = useState(0);
  const [donationsSumByYear, setDonationsSumByYear] = useState(0);

  useEffect(() => {
    const getStats = async () => {
      try {
        showLoader(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/recus-fiscaux/statistics/${selectedYear}`
        );
        if (res.code === 200) {
          showLoader(false);
          setRfNumberTotal(res.rfNumberTotal);
          setRfNumberByYear(res.rfNumberByYear);
          setDonationsNumberByYear(res.donationsNumberByYear);
          setDonationsSumByYear(res.donationsSumByYear);
        }
      } catch (error) {
        showLoader(false);
        alert("ERROR OCCURED");
        console.log(error);
      }
    };
    getStats();
  }, [selectedYear, showLoader]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleDowloadRfsubmit = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;

    if (!email || email.length < 5) {
      setAlert("Email est obligatoire", "error");
      return false;
    }

    try {
      showLoader(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/recus-fiscaux/request-download/${selectedYear}`,
        {
          email: email,
        }
      );
      if (res.code === 200) {
        showLoader(false);
        setAlert(res.msg, "success");
      } else {
        showLoader(false);
        setAlert(res.msg, "info");
      }
    } catch (error) {
      showLoader(false);
      setAlert("Une erreur est survenue", "error");
      console.log(error);
    }
  };

  return (
    <div>
      <div style={{ width: "95%", margin: "100px auto" }}>
        <div className="rf__cards__container__year">
          <div
            className="rf__cards__item"
            style={{
              textAlign: "center",
              margin: "auto",
              height: "-webkit-fill-available",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl style={{ width: "200px" }}>
              <InputLabel id="demo-simple-select-label">
                <b>Année</b>
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedYear}
                onChange={handleYearChange}
                style={{ textAlign: "left" }}
              >
                {yearsDispo.map((year, index) => (
                  <MenuItem value={year} key={index}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            className="rf__cards__item"
            style={{
              textAlign: "center",
              margin: "auto",
              display: "flex",
              height: "-webkit-fill-available",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form onSubmit={handleDowloadRfsubmit}>
              {/* Still in dev */}
              <TextField
                id="donwloadRfEmail"
                label="Saisissez Votre email"
                name="email"
                fullWidth={true}
                style={{ marginBottom: "20px" }}
              />
              <Button
                fullWidth={true}
                type="submit"
                variant="contained"
                color="primary"
              >
                Télécharger les rf {selectedYear}
              </Button>
            </form>
          </div>
        </div>
        <div className="rf__cards__container">
          <div className="rf__cards__item">
            <h4>Rf Total</h4>
            <p>{rfNumberTotal}</p>
          </div>
          <div className="rf__cards__item">
            <h4>Rf / {selectedYear}</h4>
            <p>{rfNumberByYear}</p>
          </div>

          <div className="rf__cards__item">
            <h4>Nbr dons / {selectedYear}</h4>
            <p>{donationsNumberByYear}</p>
          </div>
          <div className="rf__cards__item">
            <h4>Sum dons / {selectedYear}</h4>
            <p>
              <CurrencyFormat amount={donationsSumByYear} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
RFStats.propTypes = {
  showLoader: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};
export default connect(null, { showLoader, setAlert })(RFStats);
