import React, { forwardRef } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import Alert from "@material-ui/lab/Alert";
// import CloseIcon from "@material-ui/icons/Close";
// import Collapse from "@material-ui/core/Collapse";
// import IconButton from "@material-ui/core/IconButton";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const Table = (props) => {
  const title = props.title;
  const headCells = props.headCells;
  const data = props.data;
  const singleLink = props.singleLink;
  const singleIdSelector = props.singleIdSelector;
  const singleOption = props.singleOption;
  const singleOption2 = props.singleOption2;
  const pagination = props.pagination;
  const actionHidden = props.actionHidden;
  const isFreeAction = props.actionHidden ? true : false;
  let actionIcon = VisibilityIcon;
  let actionIcon1 = UpdateIcon;
  let tooltip = "Afficher les détails";
  let tooltip1 = "";
  let action1Hidden = true;
  //console.log(props.actionIcon);
  if (props.actionIcon === "update") {
    actionIcon = UpdateIcon;
    tooltip = "Mettre à jour tous les dons";
  } else if (props.actionIcon === "update + details") {
    action1Hidden = false;
    actionIcon1 = UpdateIcon;
    tooltip1 = "Mettre à jour tous les dons de cette campagne";
  }

  const donationsUpdateHandler = async (
    campName,
    campId,
    campGivenName,
    campGivenId
  ) => {
    props.handleDialogOpencallback(true, {
      campName,
      campId,
      campGivenName,
      campGivenId,
    });
  };

  const updateHandler = (campaignName) => {
    props.handleDialogOpencallback(true, campaignName);
  };
  return (
    <div>
      <MaterialTable
        icons={tableIcons}
        title={title}
        columns={headCells}
        data={data}
        options={{
          exportButton: true,
          paging: pagination,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100],
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Vide",
          },
          toolbar: {
            searchTooltip: "Rechercher",
            searchPlaceholder: "Rechercher",
          },
          pagination: {
            labelRowsSelect: "Lignes",
            labelDisplayedRows: "{from}-{to} De {count}",
            firstTooltip: "Première page",
            previousTooltip: "Page précédente",
            nextTooltip: "Page suivante",
            lastTooltip: "Dernière page",
          },
        }}
        actions={[
          {
            isFreeAction: isFreeAction,
            hidden: actionHidden,
            icon: actionIcon,
            tooltip: tooltip,
            onClick: (event, rowData) => {
              // console.log(rowData[singleIdSelector].replace(/\//g, "___"));
              if (
                rowData[singleIdSelector] ||
                window.location.pathname === "/campaigns/autres"
              ) {
                if (singleOption2) {
                  if (
                    singleIdSelector !== "id" &&
                    singleIdSelector !== "campaign_id"
                  ) {
                    props.history.push(
                      singleLink +
                        rowData[singleIdSelector].replace(/\//g, "___") +
                        "/" +
                        singleOption +
                        "/" +
                        singleOption2
                    );
                  } else {
                    props.history.push(
                      singleLink +
                        rowData[singleIdSelector] +
                        "/" +
                        singleOption +
                        "/" +
                        singleOption2
                    );
                  }
                } else if (singleOption) {
                  if (
                    singleIdSelector !== "id" &&
                    singleIdSelector !== "campaign_id"
                  ) {
                    props.history.push(
                      singleLink +
                        rowData[singleIdSelector].replace(/\//g, "___") +
                        "/" +
                        singleOption
                    );
                  } else {
                    props.history.push(
                      singleLink +
                        rowData[singleIdSelector] +
                        "/" +
                        singleOption
                    );
                  }
                } else {
                  //window.location.href = singleLink + rowData[singleIdSelector];
                  var rowDatasingleIdSelector = rowData[singleIdSelector];
                  if (!rowData[singleIdSelector]) {
                    rowDatasingleIdSelector = "%20";
                  }

                  if (
                    singleIdSelector !== "id" &&
                    singleIdSelector !== "campaign_id"
                  ) {
                    props.history.push(
                      singleLink + rowDatasingleIdSelector.replace(/\//g, "___")
                    );
                  } else {
                    props.history.push(singleLink + rowDatasingleIdSelector);
                  }
                }
              }
              if (props.actionIcon && props.actionIcon === "update") {
                // mettre a jour les dons
                donationsUpdateHandler(
                  rowData["name"],
                  rowData["id"],
                  rowData["campaignParent"]["name"],
                  rowData["campaignParent"]["id"]
                );
              }
            },
          },
          {
            isFreeAction: isFreeAction,
            hidden: action1Hidden,
            icon: actionIcon1,
            tooltip: tooltip1,
            onClick: (event, rowData) => {
              // console.log(rowData.campaign_name);
              updateHandler(rowData.name);
            },
          },
        ]}
      />
    </div>
  );
};
export default Table;
