import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { showLoader } from "../../../providers/actions/loader";
import { setAlert } from "../../../providers/actions/alert";
import PropTypes from "prop-types";

import {
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  Link,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SendIcon from "@material-ui/icons/Send";

import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// redux
import { useSelector } from "react-redux";

import API from "../../../utils/API";
import RefundRequestPopup from "../RefundRequestPopup";

import "../main.css";

const DonationDetails = ({ match, showLoader, setAlert }) => {
  let history = useHistory();

  const loggedInUserInfo = useSelector((state) => state.isLogged);

  const [editDisabled, setEditDisabled] = useState(true);

  useEffect(() => {
    if (loggedInUserInfo) {
      setEditDisabled(loggedInUserInfo.restrictEdit);
    }
  }, [loggedInUserInfo]);

  const [donation, setDonation] = useState([]);
  const [donationId, setDonationId] = useState("");
  const [donationRegularity, setDonationRegularity] = useState("");
  const [donationCampaign, setDonationCampaign] = useState("");
  const [campaignsParent, setcampaignsParent] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [donationAmount, setDonationAmount] = useState("");
  const [donationGeteway, setDonationGeteway] = useState("");
  const [donationCampaignParent, setDonationCampaignParent] = useState("");
  const [donationCheckNumber, setDonationCheckNumber] = useState("");
  const [donationBank, setDonationBank] = useState("");

  const [donationDonorInfo, setDonationnDonorInfo] = useState({});

  const [donationDate, setDonationDate] = useState(new Date());

  const [receiptUrl, setReceiptUrl] = useState("");

  const handleDonationCampaignChange = (event) => {
    setDonationCampaign(event.target.value);
  };

  const handleDonationCampaignParentExistChange = (event) => {
    const camptoset = `${event.target.value}`;
    var setParent = false;
    campaigns.forEach((campaign) => {
      if (campaign.name === camptoset) {
        setDonationCampaignParent(
          campaign.campaignParent.id + ";" + campaign.campaignParent.name
        );
        setParent = true;
      }
    });

    if (!setParent) {
      setDonationCampaignParent("13;Autres");
    }
    if (!camptoset) {
      setDonationCampaignParent("13;Autres");
    }
  };

  const handleDonationCheckNumberChange = (event) => {
    setDonationCheckNumber(event.target.value);
  };

  const handleBankChange = (event) => {
    setDonationBank(event.target.value);
  };

  const handleDateChange = (date) => {
    setDonationDate(date);
  };

  const singleDonationId = match.params.id;

  const [refundDialogOpen, setRefundDialogOpen] = useState(false);

  const [reloadDonation, setReloadDonation] = useState(false);

  const [refundRequest, setRefundRequest] = useState({});

  useEffect(() => {
    const fetchParentCampaigns = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns-parent`
        );

        const parentcampaigns = response.data;
        if (response.code === 200) {
          setcampaignsParent(parentcampaigns);
        }
      } catch (error) {
        alert("ERROR OCCURED")
        console.log(error);
      }
    };
    const getAllCampaigns = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns/all`
        );

        setCampaigns(response.data);
      } catch (error) {
        alert("ERROR OCCURED")
        console.log(error);
      }
    };

    getAllCampaigns();

    fetchParentCampaigns();
  }, []);

  useEffect(() => {
    const fetchDonationDetails = async () => {
      showLoader(true);

      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donations/${singleDonationId}`
        );

        const data = response.data;
        if (response.code === 200) {
          setDonation(data);
          setDonationId(data.id);
          setDonationCampaign(data.campaign.name);
          if (data.campaignParent)
            setDonationCampaignParent(
              data.campaignParent.id + ";" + data.campaignParent.name
            );
          var geteway = "";

          var donationAmountReceived = data.amount;
          geteway = data.geteway;

          setDonationGeteway(geteway);
          setDonationAmount(donationAmountReceived);
          setDonationDate(data.paymentDate);

          setDonationCheckNumber(data.metadata ? data.metadata.check_num : "");
          setDonationBank(data.metadata ? data.metadata.banque_name : "");
          setDonationRegularity(data.regularity);

          const infoDonor = data.user ? data.user.donor : data.donor;

          setDonationnDonorInfo({
            ...infoDonor,
            email: data.user ? data.user.email : "",
          });
          setReceiptUrl(
            `${process.env.REACT_APP_NEW_BASEURL_APIS}/recu-fiscal/${data.id}/by-donation`
          );

          document
            .querySelector("#pdfPreview")
            .setAttribute(
              "data",
              `${process.env.REACT_APP_NEW_BASEURL_APIS}/recu-fiscal/${data.id}/by-donation`
            );

          showLoader(false);
        }
        
      } catch (error) {
        alert("ERROR OCCURED")
        console.log(error)
      }
    };

    fetchDonationDetails();
  }, [singleDonationId, reloadDonation, showLoader]);

  useEffect(() => {
    const fetchRefundRequested = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/refund-requests/${singleDonationId}/show`
        );

        if (response.code === 200) {
          setRefundRequest(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchRefundRequested();
  }, [singleDonationId, reloadDonation]);

  const getGetewayLink = () => {
    let link = "";
    if (donation.geteway === "stripe") {
      link =
        "https://dashboard.stripe.com/payments/" + donation.idGetewayPayment;
    } else if (donation.geteway === "paypal") {
      link =
        "https://www.paypal.com/activity/payment/" + donation.idGetewayPayment;
    } else if (donation.geteway === "gocardless") {
      link =
        "https://manage.gocardless.com/payments/" + donation.idGetewayPayment;
    } else if (donation.geteway === "stripe") {
      link =
        "https://dashboard.stripe.com/payments/" + donation.idGetewayPayment;
    }
    return link;
  };

  const updateDonation = async () => {
    try {
      showLoader(true);
      const response = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/donations/${singleDonationId}`,
        {
          id: donationId,
          amount: donationAmount,
          donationBankName: donationBank,
          donationCheckNumber: donationCheckNumber,
          paymentDate: donationDate,
          campaignParent: donationCampaignParent.split(";")[1],
          campaignParentId: donationCampaignParent.split(";")[0],
          campaign: donationCampaign,
        }
      );

      if (response.code === 200) {
        showLoader(false);
        setAlert("Don mis à jour avec succes", "success");
        document
          .querySelector("#pdfPreview")
          .setAttribute(
            "src",
            `${process.env.REACT_APP_NEW_BASEURL_APIS}/recu-fiscal/${singleDonationId}/by-donation`
          );
      } else {
        showLoader(false);
        setAlert(response.msg, "info");
      }
      // console.log(data);
    } catch (error) {
      showLoader(false);
      setAlert("Une erreur est survenue", "error");
      console.log(error);
    }
  };

  const deleteDonation = async () => {
    let confirm = window.confirm("Vous voulais vraiment supprimer ce don.");

    if (confirm) {
      showLoader(true);
      const response = await API(
        "DELETE",
        `${process.env.REACT_APP_API_BASE_URL}/donations/${donationId}`
      );

      if (response.code === 200) {
        showLoader(false);
        setAlert("Don supprimé", "success");
        setTimeout(() => {
          history.push("/donations");
        }, 2000);
      } else {
        showLoader(false);
        setAlert(response.msg, "error");
      }
    }
  };

  const handleDonationAmountChange = (e) => {
    setDonationAmount(e.target.value.replace(",", "."));
  };

  const handleRefunDialogCallback = (refundOpen) => {
    setRefundDialogOpen(refundOpen);
    setReloadDonation(true);
  };

  return (
    <div>
      {/* Donations Details */}
      <div style={{ width: "90%", margin: "100px auto 20px" }}>
        <div align="right">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setRefundDialogOpen(true)}
            startIcon={<SendIcon />}
            disabled={refundRequest ? true : false}
          >
            Demande de remboursement
          </Button>
        </div>
        <div align="right">
          {refundRequest ? (
            <span
              className={`status__badge ${
                refundRequest.status
                  ? refundRequest.status.replace(" ", "_")
                  : ""
              }`}
            >
              {refundRequest.status}
            </span>
          ) : (
            ""
          )}
        </div>

        <h2>Details Don</h2>
        <Paper style={{ padding: "20px" }}>
          <Grid container direction="row">
            <Grid item md={12} sm={12} xs={12}>
              <p>
                <b>Status du paiement</b> :{" "}
                <span style={{ textTransform: "uppercase" }}>
                  {donation.status ? donation.status : "PAID"}
                </span>
              </p>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Passerelle id: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <p>
                    <a
                      href={getGetewayLink()}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {donation.idGetewayPayment}
                    </a>
                  </p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Passerelle: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <p>{donationGeteway}</p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Regularité: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <p>{donationRegularity ? donationRegularity : "inconnu"}</p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Don: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <TextField
                    style={customStyles.myform}
                    id="standard-basic"
                    value={donationAmount}
                    onChange={handleDonationAmountChange}
                    disabled={editDisabled}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Date: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <MuiPickersUtilsProvider
                    locale={frLocale}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={donationDate}
                      onChange={handleDateChange}
                      fullWidth={true}
                      style={{ marginTop: 0 }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Campagne: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <input
                    style={customStyles.myform}
                    type="text"
                    list="campaigns_suggestions"
                    className="filter_input"
                    value={donationCampaign}
                    onChange={handleDonationCampaignChange}
                    onBlur={handleDonationCampaignParentExistChange}
                    disabled={editDisabled}
                  />
                  <datalist id="campaigns_suggestions">
                    {campaigns.map((campaign, index) => (
                      <option key={index}>{campaign.name}</option>
                    ))}
                  </datalist>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Campagne parent: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <Select
                    disabled={true}
                    style={customStyles.myform}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper-label"
                    value={donationCampaignParent}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: "200px" } },
                    }}
                  >
                    {campaignsParent.map((val, index) => (
                      <MenuItem key={val.name} value={val.id + ";" + val.name}>
                        {val.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              {donation.paymentMode === "Chèque" ? (
                <Grid container>
                  <Grid item md={4} sm={6} xs={12}>
                    <p>
                      <b>Numero de Chèque: </b>
                    </p>
                  </Grid>
                  <Grid item md={8} sm={6} xs={12}>
                    <TextField
                      style={customStyles.myform}
                      id="checkNum"
                      value={donationCheckNumber}
                      onChange={handleDonationCheckNumberChange}
                      disabled={editDisabled}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              {donation.paymentMode === "Virement" ? (
                <Grid container>
                  <Grid item md={4} sm={6} xs={12}>
                    <p>
                      <b>Banque: </b>
                    </p>
                  </Grid>
                  <Grid item md={8} sm={6} xs={12}>
                    <TextField
                      style={customStyles.myform}
                      id="bankName"
                      value={donationBank}
                      onChange={handleBankChange}
                      disabled={editDisabled}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Paper>
        <div className="donation__details__actions__container">
          <Button
            disabled={editDisabled}
            style={customStyles.btnSubmit}
            color="primary"
            fullWidth={true}
            variant="contained"
            onClick={updateDonation}
            startIcon={<UpdateIcon />}
          >
            Mettre à jour
          </Button>
          <Button
            disabled={editDisabled}
            style={customStyles.btnDelete}
            color="secondary"
            fullWidth={true}
            variant="contained"
            onClick={deleteDonation}
            startIcon={<DeleteIcon />}
          >
            Supprimer
          </Button>
        </div>
      </div>

      {/* Donor Details */}
      <div style={{ width: "90%", margin: "0 auto" }}>
        <h2>Details Donateur</h2>
        <Paper style={{ padding: "20px" }}>
          <Grid container direction="row">
            <Grid item md={6} sm={12} xs={12}>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditIcon />}
                  href={`/donors/${donationDonorInfo.id}/details`}
                  target="_blank"
                  disabled={editDisabled}
                >
                  MÀJ les infos donateur
                </Button>
              </div>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Passerelle id: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  <p>{donation.idGetewayDonor}</p>
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Nom: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.lastName}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Prénom: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.firstName}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Email: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.email}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Téléphone: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.phoneNumber}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Raison sociale: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.company}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Adresse: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.address}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Ville: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.city}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Pays: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.country}
                </Grid>
              </Grid>
              <Grid container alignItems="center">
                <Grid item md={4} sm={6} xs={12}>
                  <p>
                    <b>Code postal: </b>
                  </p>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                  {donationDonorInfo.zipCode}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <p>
                <b>Reçu fiscale: </b>
                {receiptUrl ? (
                  <IconButton
                    href={receiptUrl}
                    target="_blank"
                    component={Link}
                    aria-label="preview on full screen"
                    style={{ padding: "5px" }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </p>

              {receiptUrl ? (
                <iframe
                  title="RF"
                  id="pdfPreview"
                  width="100%"
                  height="500px"
                  type="application/pdf"
                  style={{ border: "none" }}
                  src={receiptUrl}
                >
                  <p>Le PDF ne peut pas être affiché.</p>
                </iframe>
              ) : (
                <p>
                  Un problème est survenu{" "}
                  <span role="img" aria-label="sry">
                    😒
                  </span>
                </p>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>

      <RefundRequestPopup
        refundDialogOpen={refundDialogOpen}
        refunDialogCallback={handleRefunDialogCallback}
        donationId={donationId}
      />
    </div>
  );
};

DonationDetails.propTypes = {
  showLoader: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

export default connect(null, { showLoader, setAlert })(DonationDetails);

const customStyles = {
  myform: {
    width: "100%",
  },
  btnDelete: {
    marginTop: "20px",
    background: "#fb2525",
  },
  btnSubmit: {
    marginTop: "20px",
  },
  alert: {
    position: "fixed",
    top: 70,
    left: 0,
    right: 0,
    margin: "auto",
    width: "max-content",
  },
};
