import React from "react";
const NotFound = () => {
  return (
    <div style={{ width: "100%", height: "100vh", display: "flex" }}>
      <div style={{ margin: "auto", textAlign: "center",}}>
        <div style={{ display: "grid", gridTemplateColumns: "auto auto", justifyContent: "center", alignItems: "center" }}>
          <div style={{borderRight: "solid 2px #ccc", padding: "10px 10px"}}>
            <h1 style={{margin: 0, fontSize: "24px", fontWeight: "500"}}>404</h1>
          </div>
          <div style={{padding: "10px"}}>
            <h1 style={{margin: 0, fontSize: "24px", fontWeight: "500"}}>Page Not Found</h1>
            <a href="/" style={{ textDecoration: "none", color: "#1976d2", fontSize: "14px" }}>
              &#x2039; Go HOME
            </a>
          </div>
          
        </div>

      </div>
    </div>
  );
};

export default NotFound;
