import React from "react";
import "./App.css";

import Routes from "./routes/Routes";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

const App = (props) => {
  return (
    <Router history={browserHistory}>
      <Routes />
    </Router>
  );
};

export default App;
