import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import API from "../../utils/API";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Regularity = () => {
  const classes = useStyles();

  const [donationsRegularities, setDonationsRegularities] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const [dateFrom, setDateFrom] = useState(
    new Date().setMonth(new Date().getMonth() - 1)
  );
  const [dateTo, setDateTo] = useState(new Date());

  const handleDateFromChange = (date) => {
    setDateFrom(date);
  };
  const handleDateToChange = (date) => {
    setDateTo(date);
  };

  const getRegularities = async () => {
    handleToggle();
    const dateFromFormated = formatDate(dateFrom);
    const dateToFormated = formatDate(dateTo);

    try {
      const response = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/donations/regularity/${dateFromFormated}/${dateToFormated}`
      );

      const data = response.data;

      var donationsByRegularities = [];
      Object.keys(data).map((el, index) => {
        donationsByRegularities.push({
          ...data[el],
          regularity: el,
        });

        return donationsByRegularities;
      });
      setDonationsRegularities(donationsByRegularities);

      handleClose();
    } catch (error) {
      handleClose();
      console.log(error);
    }
  };

  useEffect(() => {
    getRegularities();
  }, []);

  const handleDateChangeClick = () => {
    getRegularities(dateFrom, dateTo);
  };
  return (
    <div>
      <div style={{ width: "90%", margin: "100px auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          <Grid item md={10} sm={12} xs={12}>
            <Paper>
              <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-from-picker"
                      label="De"
                      value={dateFrom}
                      onChange={handleDateFromChange}
                      KeyboardButtonProps={{
                        "aria-label": "Date From",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-to-picker"
                      label="À"
                      value={dateTo}
                      onChange={handleDateToChange}
                      KeyboardButtonProps={{
                        "aria-label": "Date To",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDateChangeClick}
                    >
                      Rechercher
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div style={{ width: "90%", margin: "100px auto" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
        >
          {donationsRegularities.length
            ? donationsRegularities.map((el, index) => {
                return (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    style={{ textAlign: "center" }}
                    key={index}
                  >
                    <Paper
                      elevation={3}
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 5px #f36e20",
                      }}
                    >
                      <h3 style={{ textTransform: "capitalize" }}>
                        {el.regularity}
                      </h3>
                      <p>{el.count}</p>
                      <p>
                        {new Intl.NumberFormat("fr-EU", {
                          style: "currency",
                          currency: "EUR",
                        }).format(el.sum_amount)}
                      </p>
                    </Paper>
                  </Grid>
                );
              })
            : "Selectionnez l'intervale de temps"}
        </Grid>
      </div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Regularity;

const formatDate = (date) => {
  let dateToFormat = new Date(date);
  let dd = dateToFormat.getDate();
  let mm = dateToFormat.getMonth() + 1;

  let yyyy = dateToFormat.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "-" + mm + "-" + yyyy;
};
