import React, { useState, useEffect } from "react";

import { Grid, Paper } from "@material-ui/core";

import imageRepas from "../../assets/images/1e1repas.png";
import imagePuits from "../../assets/images/puits.png";
import imageSapousse from "../../assets/images/sapousse.png";

import API from "../../utils/API";

const LastMonthNumbers = () => {
  const [repas, setRepas] = useState(0);
  const [arbres, setArbres] = useState(0);
  const [puits, setPuits] = useState(0);
  const [month, setMonth] = useState("");

  useEffect(() => {
    const getLastMonthNumbers = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_NEW_BASEURL_APIS}/dashboard/projects`
        );
        const numbers = response.data;

        // for formatting numbers
        var nf = Intl.NumberFormat();

        setRepas(nf.format(numbers.meals));
        setArbres(nf.format(numbers.trees));
        setPuits(nf.format(numbers.wells));

        setMonth(numbers.month);
      } catch (error) {
        console.log(error);
      }
    };
    getLastMonthNumbers();
  }, []);
  return (
    <div className="container__inner">
      <h2>Le mois dernier: «{month}»</h2>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
      >
        <Grid item md={4} sm={4} xs={12} style={{ textAlign: "center" }}>
          <Paper
            elevation={3}
            style={{
              padding: "10px 0",
              borderBottom: "solid 5px rgb(215, 63, 64)",
            }}
          >
            <img width="23%" src={imageRepas} alt="1e1repas" />
            <p>{repas}</p>
            <p>Repas</p>
          </Paper>
        </Grid>
        <Grid item md={4} sm={4} xs={12} style={{ textAlign: "center" }}>
          <Paper
            elevation={3}
            style={{
              padding: "10px 0",
              borderBottom: "solid 5px rgb(135, 158, 48)",
            }}
          >
            <img width="20%" src={imageSapousse} alt="1e1repas" />
            <p>{arbres}</p>
            <p>Arbres</p>
          </Paper>
        </Grid>
        <Grid item md={4} sm={4} xs={12} style={{ textAlign: "center" }}>
          <Paper
            elevation={3}
            style={{
              padding: "10px 0",
              borderBottom: "solid 5px rgb(112, 178, 245)",
            }}
          >
            <img width="20%" src={imagePuits} alt="1e1repas" />
            <p>{puits}</p>
            <p>Puits</p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default LastMonthNumbers;
