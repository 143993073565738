import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { Route } from "react-router-dom";

import RouteWithLayout from "./RouteWithLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import NotFound from "../layouts/404";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Donations from "../views/Donations";
import DonationCreate from "../views/Donations/DonationCreate";
import DonationDetails from "../views/Donations/DonationDetails";
import DonationsByCampaign from "../views/DateAndCampaigns/DonationsByCampaign";
// import DonationByGetway from "./views/Donations/DonationByGetway";
import Campaigns from "../views/Campaign/Campaigns";
import CampaignsOthers from "../views/Campaign/CampaignsOthers";
import Exporter from "../views/Exporter";
import Profile from "../views/Profile";
import Regularity from "../views/Regularity";
import OthersDetails from "../views/Campaign/OthersDetails";
import DonationByDate from "../views/DateAndCampaigns/DonationByDate";
import FiscalReceipt from "../views/FiscalReceipt";
import Donors from "../views/Donors";
import DonorDetails from "../views/Donors/DonorDetails";
import RefundRequests from "../views/RefundRequests";
import RefundDetails from "../views/RefundRequests/RefundDetails";
import FiscalReceiptDetails from "../views/FiscalReceipt/FiscalReceiptDetails";
import Details from "../views/DonationsCertificates/Details";
import Companies from "../views/Company";
// import DonationsTracking from "../views/DonationsTracking";

// import Contact from "../views/Conact";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        path="/"
        exact
        component={Dashboard}
        layout={DefaultLayout}
      />

      <Route component={NotFound} exact path="/not-found" />

      <Route component={Login} exact path="/login" />

      {/* donations */}
      <RouteWithLayout
        path="/donations/:page?/:offset?"
        exact
        component={Donations}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/donation"
        exact
        component={DonationCreate}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/donation/:id"
        component={DonationDetails}
        layout={DefaultLayout}
      />

      {/* FILTER */}
      <RouteWithLayout
        path="/donations-by-date"
        component={DonationByDate}
        layout={DefaultLayout}
      />

      {/* CAMPAIGNS */}
      <RouteWithLayout
        path="/campaigns"
        exact
        component={Campaigns}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/campaigns/autres"
        exact
        component={CampaignsOthers}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/autres/:campname?"
        component={OthersDetails}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/donations-campaign/:id/:date/:dateto?"
        component={DonationsByCampaign}
        layout={DefaultLayout}
      />

      {/* RF */}
      <RouteWithLayout
        path="/fiscal-receipts/:page?"
        exact
        component={FiscalReceipt}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/fiscal-receipts/:id/details"
        exact
        component={FiscalReceiptDetails}
        layout={DefaultLayout}
      />

      {/* DC */}
      <RouteWithLayout
        path="/donations-certificates/:id/details"
        exact
        component={Details}
        layout={DefaultLayout}
      />

      {/* DONOR */}
      <RouteWithLayout
        path="/donors/:page?"
        exact
        component={Donors}
        layout={DefaultLayout}
      />
      <RouteWithLayout
        path="/donors/:id/details"
        exact
        component={DonorDetails}
        layout={DefaultLayout}
      />

      {/* RFUND REQUESTS */}
      <RouteWithLayout
        path="/refund-requests"
        exact
        component={RefundRequests}
        layout={DefaultLayout}
      />

      <RouteWithLayout
        path="/refund-requests/:id/details"
        exact
        component={RefundDetails}
        layout={DefaultLayout}
      />

      {/* EXPORTER */}
      <RouteWithLayout
        path="/exporter"
        exact
        component={Exporter}
        layout={DefaultLayout}
      />

      <RouteWithLayout
        path="/donations-by-regularity"
        exact
        component={Regularity}
        layout={DefaultLayout}
      />

      {/* companies donations */}
      <RouteWithLayout
        path="/companies-donations/:page?"
        exact
        component={Companies}
        layout={DefaultLayout}
      />
      
      {/* <RouteWithLayout
        path="/donations-tracking"
        exact
        component={DonationsTracking}
        layout={DefaultLayout}
      /> */}

      {/* PROFILE */}
      <RouteWithLayout
        path="/profile"
        exact
        component={Profile}
        layout={DefaultLayout}
      />

      {/* NOT FOUND */}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
