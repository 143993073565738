import React, { useState, useEffect } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";

import API from "../../../utils/API";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: "8px 0",
    minWidth: "100%",
  },
}));

const NewCampaign = ({
  parentCallback,
  visible,
  campaignName,
  campaignParentId = "",
  campaignParentName = "",
  provider,
}) => {
  const classes = useStyles();
  const [parentCampaigns, setParentCampaigns] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [severity, setSeverity] = useState("success");
  const [compProvider, setcompProvider] = useState("success");

  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [selectedCampaignName, setSelectedCampaignName] = useState("");

  const [selectedValue, setSelectedValue] = useState("");
  const [newCampaignName, setNewCampaignName] = useState("");

  useEffect(() => {
    const getAllParentCampaigns = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/campaigns-parent`
        );
        setParentCampaigns(response.data);

        if (campaignParentId && campaignParentName) {
          setSelectedValue(campaignParentId + ";" + campaignParentName);
          setSelectedCampaignName(campaignParentName);
          setSelectedCampaignId(campaignParentId);
        }
      } catch (error) {
        console.log(error);
        alert("ERROR OCCURED");
      }
    };

    getAllParentCampaigns();

    if (campaignName) {
      setNewCampaignName(campaignName);
    }

    setcompProvider(provider);

    return () => {
      setParentCampaigns([]);
      setSelectedValue("");
      setSelectedCampaignName("");
      setSelectedCampaignId("");
    };
  }, [visible, provider, campaignName, campaignParentId, campaignParentName]);

  const handleDialogClose = (rereload = false) => {
    parentCallback({
      dialogOpen: false,
      rereload: rereload,
    });
  };

  const handleCampaignNameChange = (event) => {
    setNewCampaignName(event.target.value);
  };
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    const nameVal = event.target.value.split(";");
    setSelectedCampaignName(nameVal[1]);
    setSelectedCampaignId(nameVal[0]);
  };

  const handleFormSubmit = async () => {
    setSeverity("success");
    setAlertMsg("Campagne ajouté avec succès");
    setCollapsed(false);
    if (
      newCampaignName !== "" &&
      selectedCampaignName !== "" &&
      selectedCampaignId !== ""
    ) {
      var method = "POST";

      if (compProvider === "campaignOthers") {
        method = "PUT";
      }

      const response = await API(
        method,
        `${process.env.REACT_APP_API_BASE_URL}/campaigns`,
        {
          campName: newCampaignName,
          campGivenId: selectedCampaignId,
        }
      );

      if (response.code === 200) {
        setCollapsed(false);
        handleDialogClose(true);
        setNewCampaignName("");
        setSelectedValue("");
      } else {
        //throw error
        setSeverity("error");
        setAlertMsg(
          "Une erreur est survenue, contactez l'administrateur du site"
        );
        setCollapsed(true);
      }
    } else {
      setSeverity("error");
      setAlertMsg(
        "Veillez entrer un nom de campagne et sélectionner une campagne parent"
      );
      setCollapsed(true);
    }
  };

  return (
    <div>
      <Dialog
        open={visible}
        onClose={() => {
          handleDialogClose(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {/* Valider */}
          {compProvider === "campaignOthers"
            ? "Mettre à jour campagne"
            : "Nouvelle campagne"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous pouvez ajouter une nouvelle campagne et l'affecter à une
            campagne parent.
          </DialogContentText>
          <Collapse in={collapsed}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setCollapsed(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {alertMsg}
            </Alert>
          </Collapse>
          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              margin="dense"
              id="campaign-name"
              label="Nom de la campagne"
              type="text"
              fullWidth
              value={newCampaignName}
              onChange={handleCampaignNameChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">
              Campagne parent
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper-label"
              value={selectedValue}
              onChange={handleSelectChange}
              MenuProps={{ PaperProps: { style: { maxHeight: "200px" } } }}
            >
              {parentCampaigns.map((val, index) => (
                <MenuItem key={val.name} value={val.id + ";" + val.name}>
                  {val.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDialogClose(false);
            }}
            color="primary"
          >
            Annuler
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            {/* Valider */}
            {compProvider === "campaignOthers" ? "Mettre à jour" : "Valider"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewCampaign;
