import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import API from "../../utils/API";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const DonorByYear = () => {
  const [donorsByYearLabels, setDonorsByYearLabels] = useState([]);
  const [donorsByYearValues, setDonorsByYearValues] = useState([]);

  useEffect(() => {
    const getDonorsByYear = async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_NEW_BASEURL_APIS}/dashboard/donor-by-year`
        );

        const numbers = response.data;

        var labels = [];
        var values = [];

        Object.keys(numbers).forEach((key) => {
          labels.push(key);
          values.push(numbers[key]);
        });

        setDonorsByYearLabels(labels);
        setDonorsByYearValues(values);
      } catch (error) {
        console.log(error);
      }
    };
    getDonorsByYear();
    return () => {
      setDonorsByYearLabels([]);
      setDonorsByYearValues([]);
    };
  }, []);

  return (
    <div className="container__inner">
      <h2>Donateurs actifs par années</h2>
      {donorsByYearValues.length > 0 ? (
        <Bar
          options={options}
          data={{
            labels: donorsByYearLabels,
            datasets: [
              {
                label: "Donateurs",
                data: donorsByYearValues,
                backgroundColor: "rgba(243, 110, 32, 0.7)",
              },
            ],
          }}
        />
      ) : (
        "Loading ..."
      )}
    </div>
  );
};

export default DonorByYear;
