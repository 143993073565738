import React, { useState, useEffect } from "react";
import { Paper, Grid } from "@material-ui/core";

// redux
import { useSelector } from "react-redux";

const Profile = () => {
  const loggedInUserInfo = useSelector((state) => state.isLogged.userInfo);

  const [pseudo, setPseudo] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (loggedInUserInfo) {
      setPseudo(loggedInUserInfo.name);
      setEmail(loggedInUserInfo.email);
    }
    // get Donorinfo
  }, [loggedInUserInfo]);

  return (
    <div style={{ margin: "100px auto" }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={6} sm={6} xs={12}>
          <h2>Informations de compte</h2>
          <Paper style={{ padding: "20px" }}>
            <table>
              <tbody>
                <tr>
                  <td width="100" style={{ padding: "20px 0" }}>
                    <b>Pseudo</b>
                  </td>
                  <td>{pseudo}</td>
                </tr>
                <tr>
                  <td width="100" style={{ padding: "20px 0" }}>
                    <b>Email</b>
                  </td>
                  <td>{email}</td>
                </tr>
              </tbody>
            </table>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;
